import { Box } from "@mui/material";
import { InterfaceScreenPanelContentData, TypePanelType } from "../data/screen.interface";
import { useEffect, useRef, useState } from "react";
import pb from "../../../shared/pocketbase";
import axios from "axios";
import * as echarts from 'echarts';


function ScreenPanelContentView(props: { data: InterfaceScreenPanelContentData }) {

    const [svgStr, setSvgStr] = useState("")

    useEffect(() => {
        if (props.data.view_id) {
            getData()
        }
    }, [props.data.view_id])

    const getData = async () => {
        const record = await pb.collection('views').getOne(props.data.view_id || "")
        const url = pb.files.getUrl(record, record?.file);
        axios.get(url)
            .then(res => {
                setSvgStr(res.data)
            })
    }

    return (
        <Box display={"flex"} dangerouslySetInnerHTML={{ __html: svgStr }} />
    )
}


function ScreenPanelContentRaw(props: { data: InterfaceScreenPanelContentData }) {
    return (
        <Box display={"flex"} dangerouslySetInnerHTML={{ __html: props.data.svg || "" }} />
    )
}

function ScreenPanelContentChart(props: { index: string, height: number, data: InterfaceScreenPanelContentData }) {

    const chartRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (chartRef.current) {
            const myChart = echarts.init(chartRef.current);
            const option = {
                legend: {
                    data: ['Sales']
                },
                xAxis: {
                    data: ["shirt", "cardign", "chiffon shirt", "pants", "heels", "socks"]
                },
                yAxis: {},
                series: [{
                    name: 'Sales',
                    type: 'bar',
                    data: [5, 20, 36, 10, 10, 20]
                }]
            };
            myChart.setOption(option);
            if (resizeObserver) resizeObserver.observe(chartRef.current);
        }
    }, [])

    const resizeObserver = new window.ResizeObserver((entries) => {
        entries.map(({ target }) => {
            const instance = echarts.getInstanceByDom(target as any);
            if (instance) {
                instance.resize();
            }
            return null;
        });
    });

    return (
        <div id={props.index} ref={chartRef} style={{ height: (props.height * 20) - 70 }}>Sunt proident culpa ullamco dolore adipisicing do laboris amet.</div>
    )

}


export default function ScreenPanelContent(props: { index: string, height: number, data: InterfaceScreenPanelContentData }) {

    const renderContent = (type: TypePanelType) => {
        switch (type) {
            case 'VIEW':
                return <ScreenPanelContentView {...props} />
            case 'RAW_VIEW':
                return <ScreenPanelContentRaw {...props} />
            case 'CHART':
                return <ScreenPanelContentChart {...props} />
            default:
                return <Box>Not implemented</Box>
        }
    }

    return renderContent(props.data.panel_type)
}