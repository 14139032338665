import { Box, Button } from "@mui/material";
import FormText from "../../../shared/form/FormText";
import { InitialDriverBinaryData, InterfaceDriverBinaryData } from "../data/driverBinary.interface";
import { useFormValues } from "../../../hooks/useFormValues";
import { useDispatch } from "react-redux";
import { createDriverBinary, updateDriverBinary } from "../data/driverBinary.api";
import { useEffect } from "react";

export default function DriverBinaryForm(props: { type: "CREATE" | "EDIT", data?: InterfaceDriverBinaryData }) {

    const dispatch = useDispatch<any>()

    const { values, setValues, handleChange } = useFormValues<InterfaceDriverBinaryData>(props.data || InitialDriverBinaryData)

    useEffect(() => {
        setValues(props.data || InitialDriverBinaryData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (props.type === "CREATE") {
            await dispatch(createDriverBinary(values))
        }
        else if (props.type === "EDIT") {
            await dispatch(updateDriverBinary(values))
        }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label={"Name"} name="name" value={values?.name} onChange={handleChange} />
            <FormText label={"Description"} name="description" value={values?.description} onChange={handleChange} />
            <FormText label={"File"} name="file" value={values?.file} onChange={handleChange} />
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={2}>
                <Button type="submit" disabled={values === props.data || values === InitialDriverBinaryData}>Submit</Button>
            </Box>
        </Box>
    )
}