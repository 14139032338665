import { Add, ArrowBack, Delete, Edit } from "@mui/icons-material";
import { AppBar, Box, Button, Card, CardContent, CardHeader, IconButton, Toolbar, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ReactGridLayout from "react-grid-layout";
import { InterfaceScreenPanelData, InitialScreenPanelData } from "../data/screen.interface";
import CustomModal from "../../../shared/CustomModal";
import ScreenEditorPanelForm from "./ScreenEditorPanelForm";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getScreenDetail, updateScreen } from "../data/screen.api";
import { RootState } from "../../../store";
import pb from "../../../shared/pocketbase";
import alertDelete from "../../../shared/alert/alertDelete";
import ScreenPanelContent from "./ScreenPanelContent";

export default function ScreenEditor() {

    const containerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const { id } = useParams<{ id: string }>();
    const theme = useTheme()

    const { dataDetail } = useSelector((state: RootState) => state.screen)

    const [layout, setLayout] = useState<InterfaceScreenPanelData[]>([]);
    const [panelSelected, setPanelSelected] = useState<InterfaceScreenPanelData | null>(null);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getScreenDetail(id));
            pb.collection('screens').subscribe('*', function (e) {
                dispatch(getScreenDetail(id));
            }, {});
        }
        return () => {
            pb.collection('screens').unsubscribe('*');
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (dataDetail.panels) {
            const originalLayout = dataDetail.panels as InterfaceScreenPanelData[]
            setLayout(originalLayout)
        }
    }, [dataDetail]);

    const handleAdd = () => {
        setLayout(last => [
            ...last,
            {
                ...InitialScreenPanelData,
                i: last.length.toString()
            }
        ])
    }

    const handleChange = (value: InterfaceScreenPanelData) => {
        setLayout(last => last.map(last => last.i === value.i ? value : last))
        setPanelSelected(null)
    }

    const handleDelete = (i: string) => {
        alertDelete().then(async (result) => {
            if (result) {
                setLayout(last => last.filter(last => last.i !== i))
            }
        })
    }

    const handleSave = () => {
        dispatch(updateScreen({ ...dataDetail, panels: layout }))
    }

    const width = containerRef.current?.offsetWidth

    return (
        <Box>
            <Toolbar></Toolbar>
            <CustomModal
                open={panelSelected !== null}
                onClose={() => setPanelSelected(null)}
                title={"Edit Panel"}
                component={<ScreenEditorPanelForm value={panelSelected || InitialScreenPanelData} onChange={handleChange} />}
            />
            <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Toolbar sx={{ bgcolor: theme.palette.primary.main }}>
                    <Box display={"flex"} flexGrow={1} justifyContent={"space-between"}>
                        <Box>
                            <IconButton onClick={() => navigate(-1)}>
                                <ArrowBack sx={{ color: theme.palette.primary.contrastText }} />
                            </IconButton>
                        </Box>
                        <Box display={"flex"} gap={1}>
                            <IconButton onClick={handleAdd}>
                                <Add sx={{ color: theme.palette.primary.contrastText }} />
                            </IconButton>
                            <Button disabled={dataDetail.panels === layout} color="success" onClick={handleSave}>Save</Button>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box ref={containerRef}>
                {width !== undefined && (
                    <ReactGridLayout
                        className="layout"
                        layout={layout}
                        width={width}
                        rowHeight={10}
                        onLayoutChange={(l) =>
                            setLayout(
                                l.map((item: any) => {
                                    const layoutContent = layout.find((i: any) => i.i === item.i);
                                    return {
                                        ...layoutContent,
                                        ...item
                                    };
                                })
                            )
                        }
                    >
                        {layout.map((item) => (
                            <Card key={item.i} variant="outlined" sx={{ bgcolor: "white", zIndex: 5 }}>
                                <CardHeader
                                    title={item.content.title}
                                    action={
                                        <Box>
                                            <IconButton onClick={() => setPanelSelected(item)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(item.i)}>
                                                <Delete />
                                            </IconButton>
                                        </Box>
                                    }
                                />
                                <CardContent className="react-grid-dragHandleExample">
                                    <ScreenPanelContent index={item.i} height={item.h} data={item.content} />
                                </CardContent>
                            </Card>
                        ))}
                    </ReactGridLayout>
                )}
            </Box>
        </Box>
    )
}