import { Mediation, Power, QueryStats, Source, WebAsset } from "@mui/icons-material";

import DashboardPage from "./pages/DashboardPage";
import ScreenPage from "./pages/ScreenPage";

import DatasourcePage from "./pages/DatasourcePage";

import DriverPage from "./pages/DriverPage";

import Viewpage from "./pages/master/ViewPage";
import DriverBinaryPage from "./pages/master/DriverBinaryPage";
import DriverCategoryPage from "./pages/master/DriverCategoryPage";

const routeList = [
    {
        name: "Dashboard",
        path: "/dashboard",
        icon: <QueryStats />,
        component: <DashboardPage />
    },
    {
        name: "Screen",
        path: "/screen",
        icon: <WebAsset />,
        component: <ScreenPage />
    },
    {
        name: "Datasource",
        path: "/datasource",
        icon: <Mediation />,
        component: <DatasourcePage />
    },
    {
        name: "Driver",
        path: "/driver",
        icon: <Power />,
        component: <DriverPage />
    },
    {
        name: "Master",
        path: "/master",
        icon: <Source />,
        child: [
            {
                name: "View",
                path: "/view",
                component: <Viewpage />
            },
            {
                name: "Driver Binary",
                path: "/driver-binary",
                component: <DriverBinaryPage />
            
            },
            {
                name: "Driver Category",
                path: "/driver-category",
                component: <DriverCategoryPage />
            
            }
        ]
    }
]

export default routeList 