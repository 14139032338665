import { Box, Button, Card, CardContent, Grid } from "@mui/material";
import FormText from "../../../shared/form/FormText";
import { useFormValues } from "../../../hooks/useFormValues";
import { useDispatch } from "react-redux";
import { InitialDriverTagData, InterfaceDriverTagData } from "../data/driver.interface";
import { createDriverTag, updateDriverTag } from "../data/driver.api";
import FormNumber from "../../../shared/form/FormNumber";
import FormCheck from "../../../shared/form/FormCheck";

export default function DriverTagForm(props: { type: "CREATE" | "EDIT", driverId: string, data?: InterfaceDriverTagData }) {

    const dispatch = useDispatch<any>()

    const { values, handleChange } = useFormValues<InterfaceDriverTagData>(props.data || InitialDriverTagData)

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (props.type === "CREATE") {
            await dispatch(createDriverTag({ ...values, driver: props.driverId }))
        }
        else if (props.type === "EDIT") {
            await dispatch(updateDriverTag(values))
        }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormText label={"Name"} name="name" value={values?.name} onChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                    <FormText label={"Description"} name="description" value={values?.description} onChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                    <FormText label={"Address"} name="address" value={values?.address} onChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                    <FormText label={"Unit"} name="unit" value={values?.unit} onChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <FormText label={"Data Type In"} name="data_type" value={values?.data_type} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormText label={"Data Type Out"} name="output_data_type" value={values?.output_data_type} onChange={handleChange} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <FormNumber label={"Scaling Input Min"} name="scaling_input_min" value={values?.scaling_input_min} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormNumber label={"Scaling Input Max"} name="scaling_input_max" value={values?.scaling_input_max} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormNumber label={"Scaling Output Min"} name="scaling_output_min" value={values?.scaling_output_min} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormNumber label={"Scaling Output Max"} name="scaling_output_max" value={values?.scaling_output_max} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormCheck label={"Clamp Scaling Output"} name="clamp_scaling_output" value={values?.clamp_scaling_output} onChange={handleChange} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={2}>
                <Button type="submit">Submit</Button>
            </Box>
        </Box>
    )
}