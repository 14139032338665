import Content from './Content';
import Sidebar from './Sidebar';
import { ReactNode } from 'react';
import { Box } from '@mui/material';
import Loading from './Loading';

export default function Layout(props: { component: ReactNode }) {

    return (
        <Box sx={{ display: 'flex' }}>
            <Loading />
            <Sidebar />
            <Content component={props.component} />
        </Box>
    )
}