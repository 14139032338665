import { Box, Button } from "@mui/material";
import FormText from "../../../shared/form/FormText";
import { InitialViewData, InterfaceViewData } from "../data/view.interface";
import { useFormValues } from "../../../hooks/useFormValues";
import { useDispatch } from "react-redux";
import { createView, updateView } from "../data/view.api";
import FormArea from "../../../shared/form/FormArea";
import { useEffect } from "react";

export default function ViewForm(props: { type: "CREATE" | "EDIT", data?: InterfaceViewData }) {

    const dispatch = useDispatch<any>()

    const { values, setValues, handleChange } = useFormValues<InterfaceViewData>(props.data || InitialViewData)

    useEffect(() => {
        setValues(props.data || InitialViewData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (props.type === "CREATE") {
            await dispatch(createView(values))
        }
        else if (props.type === "EDIT") {
            await dispatch(updateView(values))
        }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label={"Title"} name="title" value={values?.title} onChange={handleChange} />
            <FormArea label={"Description"} name="description" value={values?.description} onChange={handleChange} />
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={2}>
                <Button type="submit" disabled={values === props.data || values === InitialViewData }>Submit</Button>
            </Box>
        </Box>
    )
}