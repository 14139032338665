import { InitialDriverBinaryData, InterfaceDriverBinaryData } from "../../driverBinary/data/driverBinary.interface"
import { InitialDriverCategoryData, InterfaceDriverCategoryData } from "../../driverCategory/data/driverCategory.interface"

export interface InterfaceDriverData {
    id: string,
    created: string,
    updated: string,
    name: string,
    description: string,
    category: string,
    binary_file: string,
    expand: {
        category: InterfaceDriverCategoryData,
        binary_file: InterfaceDriverBinaryData
    }
}

export interface InterfaceDriverCreate {
    name: string
}

export interface InterfaceDriverUpdate {
    id: string,
    name?: string
}

export interface InterfaceDriverTagData {
    id: string,
    created: string,
    updated: string,
    name: string,
    description: string,
    driver: string,
    address: string,
    data_type: string,
    output_data_type: string
    scaling_input_min: number,
    scaling_input_max: number,
    scaling_output_min: number,
    scaling_output_max: number,
    clamp_scaling_output: boolean,
    unit: string
}

export interface InterfaceDriverTagCreate {
    name: string,
    description: string,
    driver: string,
    address: string,
    data_type: string,
    output_data_type: string,
    scaling_input_min: number,
    scaling_input_max: number,
    scaling_output_min: number,
    scaling_output_max: number,
    clamp_scaling_output: boolean,
    unit: string
}

export interface InterfaceDriverTagUpdate {
    id: string,
    name?: string,
    description?: string,
    address?: string,
    data_type?: string,
    output_data_type?: string,
    scaling_input_min?: number,
    scaling_input_max?: number,
    scaling_output_min?: number,
    scaling_output_max?: number,
    clamp_scaling_output?: boolean,
    unit?: string
}

export const InitialDriverData: InterfaceDriverData = {
    id: "",
    created: "",
    updated: "",
    name: "",
    description: "",
    category: "",
    binary_file: "",
    expand: {
        category: InitialDriverCategoryData,
        binary_file: InitialDriverBinaryData
    }
}

export const InitialDriverTagData: InterfaceDriverTagData = {
    id: "",
    created: "",
    updated: "",
    name: "",
    description: "",
    driver: "",
    address: "",
    data_type: "",
    output_data_type: "",
    scaling_input_min: 0,
    scaling_input_max: 0,
    scaling_output_min: 0,
    scaling_output_max: 0,
    clamp_scaling_output: false,
    unit: ""
}