import "./assets/style.scss"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { CssBaseline } from '@mui/material';
import Layout from './containers/components/Layout';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import routeList from './routeList';
import Login from "./features/auth/components/Login";
import pb from "./shared/pocketbase";
import ScreenEditor from "./features/screen/components/ScreenEditor";

function App() {

  const navigate = useNavigate()

  const [pathAndComponentList, setPathAndComponentList] = useState<Array<{ path: string, component: ReactNode }>>([])

  useEffect(() => {
    if (pb.authStore.isValid) {
      let pathAndComponentListNew: any[] = []
      routeList.map(route => {
        if (route.child) {
          route.child.map(child => {
            pathAndComponentListNew.push({ path: route.path + child.path, component: child.component })
            return null
          })
        }
        else if (route.path) {
          pathAndComponentListNew.push({ path: route.path, component: route.component })
        }
        return null
      })
      setPathAndComponentList(pathAndComponentListNew)
    }
    else {
      navigate("/login")
    }
  }, [navigate])

  return (
    <Fragment>
      <CssBaseline />
      <Routes>
        {pathAndComponentList.map((item: any, i: number) => (
          <Route key={i} path={item.path} element={<Layout component={item.component} />} />
        ))}
        <Route path="/" element={<Navigate to='/dashboard' />} />
        <Route path="/login" element={<Login />} />
        <Route path="/screen/editor/:id" element={<ScreenEditor />} />
      </Routes>
    </Fragment>
  )
}

export default App;
