import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import routeList from '../../routeList';
import { useNavigate } from "react-router-dom"
import { useState } from 'react';
import { ArrowDropDown, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, Collapse, IconButton, ListItem, Popover, Typography, useTheme } from '@mui/material';
import pb from '../../shared/pocketbase';
import logo_sm from "../../assets/images/logo_sm.png"

const drawerWidth = 240;

export default function Sidebar() {

    const navigate = useNavigate()
    const theme = useTheme()

    const [expanded, setExpanded] = useState("")
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleLogout = () => {
        pb.authStore.clear()
        window.location.replace("/")
    }

    const handleClickMenu = (menu: any, child: any = null) => {
        if (Boolean(child)) {
            navigate(menu.path + child.path)
        }
        else {
            if (!menu.child) {
                navigate(menu.path)
            }
            else if (expanded === menu.path) {
                setExpanded("")
            }
            else {
                setExpanded(menu.path)
            }
        }
    }

    const url = window.location.hash.replace("#", "")
    const isMenuActive = (menu: any) => {
        return url === menu.path || (expanded !== menu.path && menu.child && menu.child.some((child: any) => url === menu.path + child.path))
    }

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }
            }}
        >
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box p={2}>
                    <Button color='primary' variant='text' onClick={handleLogout}>Logout</Button>
                </Box>
            </Popover>
            <Box sx={{ overflow: 'auto', bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText }} display="flex" flexGrow={1} flexDirection="column" justifyContent="space-between">
                <List>
                    <ListItem>
                        <img src={logo_sm} alt="logo" style={{ width: "100%", padding: "16px" }} />
                    </ListItem>
                    {routeList
                        .map((menu, i) => (
                            <div key={i}>
                                <ListItemButton onClick={() => handleClickMenu(menu)} sx={{ bgcolor: isMenuActive(menu) ? "#00000040 !important" : undefined }}>
                                    <ListItemIcon sx={{ color: theme.palette.primary.contrastText }}>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.name} />
                                    {menu.child && (expanded === menu.path ? <ExpandLess /> : <ExpandMore />)}
                                </ListItemButton>
                                {menu.child && (
                                    <Collapse in={expanded === menu.path}>
                                        <List disablePadding>
                                            {menu.child
                                                .map((child: any, i: number) => (
                                                    <ListItemButton sx={{ bgcolor: url === (menu.path + child.path) ? "#00000040 !important" : undefined }} key={i} onClick={() => handleClickMenu(menu, child)}>
                                                        <ListItemIcon></ListItemIcon>
                                                        <ListItemText primary={child.name} />
                                                    </ListItemButton>
                                                ))}
                                        </List>
                                    </Collapse>
                                )}
                            </div>
                        ))}
                </List>
                <Box display={"flex"} gap={1} justifyContent={"center"} alignItems={"center"} padding={2}>
                    <Typography variant="h6">
                        {pb.authStore.model?.name}
                    </Typography>
                    <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} >
                        <ArrowDropDown sx={{ color: theme.palette.primary.contrastText }} />
                    </IconButton>
                </Box>
            </Box>
        </Drawer>
    )
}