import { Box, Button, Container, Typography } from "@mui/material";
import Split from "@uiw/react-split";
import { useEffect, useState } from "react";
import CustomTable from "../shared/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { deleteDatasource, getDatasource } from "../features/datasource/data/datasource.api";
import pb from "../shared/pocketbase";
import DatasourcesForm from "../features/datasource/components/DatasourceForm";
import alertDelete from "../shared/alert/alertDelete";
import { Check, Close } from "@mui/icons-material";
import { InterfaceDatasourceData } from "../features/datasource/data/datasource.interface";

export default function DatasourcesPage() {

    const dispatch = useDispatch<any>()

    const { dataList, dataTotal } = useSelector((state: RootState) => state.datasource)

    const [pagination, setPagination] = useState<{ page: number, perPage: number }>({ page: 1, perPage: 5 })
    const [selectedIdList, setSelectedIdList] = useState<string[]>([])
    const [addMode, setAddMode] = useState<boolean>(false)

    useEffect(() => {
        dispatch(getDatasource(pagination))
        pb.collection('datasources').subscribe('*', function (e) {
            dispatch(getDatasource(pagination))
        }, {});
        return () => {
            pb.collection('datasources').unsubscribe('*');
        }
    }, [dispatch, pagination])

    const handleAdd = () => {
        if (selectedIdList.length > 0) {
            setSelectedIdList([])
            const i = setTimeout(() => {
                setAddMode(!addMode)
                clearTimeout(i)
            }, 100);
        }
        else {
            setAddMode(!addMode)
        }
    }

    const handleDelete = () => {
        alertDelete().then(async (result) => {
            if (result) {
                for (const i in selectedIdList) {
                    if (Object.prototype.hasOwnProperty.call(selectedIdList, i)) {
                        const element = selectedIdList[i];
                        await dispatch(deleteDatasource(element))
                    }
                }
                setSelectedIdList([])
            }
        })
    }

    const openSidetab = selectedIdList.length === 1 || addMode

    return (
        <Split style={{ display: "flex", flexGrow: 1 }}>
            <Box flexGrow={1} padding={1} overflow={"auto"}>
                <Container>
                    <Typography variant="h3" gutterBottom>Datasource</Typography>
                    <Box display={"flex"} gap={1} justifyContent={"flex-end"} marginBottom={2}>
                        <Button onClick={handleAdd}>{addMode ? "Close Add Panel" : "Add"}</Button>
                        {selectedIdList.length > 0 && (<Button color="error" onClick={handleDelete}>Delete Selected</Button>)}
                    </Box>
                    <CustomTable
                        dataList={dataList}
                        dataTotal={dataTotal}
                        pagination={(data) => setPagination(data)}
                        columns={[
                            {
                                name: "Name",
                                key: "name"
                            },
                            {
                                name: "Description",
                                key: "description"
                            },
                            {
                                name: "Enabled",
                                key: "enabled",
                                render: (data: InterfaceDatasourceData) => data.enabled ? <Check/> : <Close/>
                            },
                            {
                                name: "Driver",
                                key: "driver",
                                render: (data: InterfaceDatasourceData) => data.expand.driver.name
                            },
                            {
                                name: "Read Interval",
                                key: "read_interval"
                            }
                        ]}
                        selectedIdList={selectedIdList}
                        setSelectedIdLIst={setSelectedIdList}
                    />
                </Container>
            </Box>
            {openSidetab && (
                <Box flexGrow={1} padding={3} overflow={"auto"}>
                    {addMode
                        ? <Box>
                            <Typography variant="h3" gutterBottom>Add</Typography>
                            <DatasourcesForm type="CREATE" data={undefined} />
                        </Box>
                        : <Box>
                            <Typography variant="h3" gutterBottom>Detail</Typography>
                            <DatasourcesForm type="EDIT" data={dataList.find(data => data.id === selectedIdList[0])} />
                        </Box>
                    }
                </Box>
            )}
        </Split >
    )
}