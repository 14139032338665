export interface InterfaceViewData {
    id: string,
    created: string,
    updated: string,
    title: string,
    description: string
}

export interface InterfaceViewCreate {
    title: string,
    description: string
}

export interface InterfaceViewUpdate {
    id: string,
    title?: string,
    description: string
}

export const InitialViewData: InterfaceViewData = {
    id: "",
    created: "",
    updated: "",
    title: "",
    description: ""
}