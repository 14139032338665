export type TypePanelType = "VIEW" | "RAW_VIEW" | "CHART"
export const ListPanelType: TypePanelType[] = ["VIEW", "RAW_VIEW", "CHART"]

export interface InterfaceScreenPanelContentData {
    title: string,
    panel_type: TypePanelType,
    view_id?: string,
    svg?: string,
}

export interface InterfaceScreenPanelData {
    i: string,
    x: number,
    y: number,
    w: number,
    h: number,
    content: InterfaceScreenPanelContentData
}

export interface InterfaceScreenData {
    id: string,
    created: string,
    updated: string,
    name: string,
    description: string,
    panels: Record<string, any>[]
}

export interface InterfaceScreenCreate {
    name: string,
    description: string,
    panels: Record<string, any>[]
}

export interface InterfaceScreenUpdate {
    id: string,
    name?: string,
    description?: string,
    panels?: Record<string, any>[]
}

export const InitialScreenData: InterfaceScreenData = {
    id: "",
    created: "",
    updated: "",
    name: "",
    description: "",
    panels: []
}

export const InitialScreenPanelData: InterfaceScreenPanelData = {
    i: "",
    x: 0,
    y: 0,
    w: 1,
    h: 1,
    content: {
        title: "New Card",
        panel_type: "VIEW"
    }
}