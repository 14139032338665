import { TextField } from "@mui/material";
import { ChangeEventHandler } from "react";

export default function FormArea(props: {
    label: string,
    name: string,
    value: string | undefined,
    required?: boolean,
    disabled?: boolean,
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}) {
    return (
        <TextField
            fullWidth
            multiline
            minRows={3}
            label={props.label}
            name={props.name}
            value={props.value || ""}
            onChange={props.onChange}
            required={props.required}
            disabled={props.disabled}
        />
    )
}