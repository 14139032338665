import Swal from "sweetalert2";

export default function alertDelete(): Promise<boolean> {
    return new Promise(resolve => {
        Swal.fire({
            icon: "warning",
            title: "Delete this data?"
        })
            .then(result => {
                resolve(result.isConfirmed)
            })
    })
}