import { InitialDriverData, InterfaceDriverData } from "../../driver/data/driver.interface"

export interface InterfaceDatasourceData {
    id: string,
    created: string,
    updated: string,
    name: string,
    description: string,
    config: Record<string, any>,
    enabled: boolean,
    driver: string,
    read_interval: number,
    expand: {
        driver: InterfaceDriverData
    }
}

export interface InterfaceDatasourceCreate {
    name: string
}

export interface InterfaceDatasourceUpdate {
    id: string,
    name?: string
}

export const InitialDatasourceData: InterfaceDatasourceData = {
    id: "",
    created: "",
    updated: "",
    name: "",
    description: "",
    config: {},
    enabled: false,
    driver: "",
    read_interval: 0,
    expand: {
        driver: InitialDriverData
    }
}