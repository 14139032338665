import { Box, Button } from "@mui/material";
import FormText from "../../../shared/form/FormText";
import { InitialDriverData, InterfaceDriverData } from "../data/driver.interface";
import { useFormValues } from "../../../hooks/useFormValues";
import { useDispatch } from "react-redux";
import { createDriver, updateDriver } from "../data/driver.api";
import DriverBinarySelect from "../../driverBinary/components/DriverBinarySelect";
import DriverCategorySelect from "../../driverCategory/components/DriverCategorySelect";
import { useEffect } from "react";

export default function DriverForm(props: { type: "CREATE" | "EDIT", data?: InterfaceDriverData }) {

    const dispatch = useDispatch<any>()

    const { values, setValues, handleChange, handleChangeValues } = useFormValues<InterfaceDriverData>(props.data || InitialDriverData)

    useEffect(() => {
        setValues(props.data || InitialDriverData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (props.type === "CREATE") {
            await dispatch(createDriver(values))
        }
        else if (props.type === "EDIT") {
            await dispatch(updateDriver(values))
        }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label={"Name"} name="name" value={values?.name} onChange={handleChange} />
            <FormText label={"Description"} name="description" value={values?.description} onChange={handleChange} />
            <DriverBinarySelect value={values?.binary_file} onChange={(value) => handleChangeValues("binary_file", value)} />
            <DriverCategorySelect value={values?.category} onChange={(value) => handleChangeValues("category", value)} />
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={2}>
                <Button type="submit" disabled={values === props.data || values === InitialDriverData }>Submit</Button>
            </Box>
        </Box>
    )
}