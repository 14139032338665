export interface InterfaceDriverBinaryData {
    id: string,
    created: string,
    updated: string,
    name: string,
    description: string,
    file: string
}

export interface InterfaceDriverBinaryCreate {
    name: string,
    description: string
}

export interface InterfaceDriverBinaryUpdate {
    id: string,
    name?: string,
    description?: string
}

export const InitialDriverBinaryData: InterfaceDriverBinaryData = {
    id: "",
    created: "",
    updated: "",
    name: "",
    description: "",
    file: ""
}