import { Box, Button, Container, Typography } from "@mui/material";
import Split from "@uiw/react-split";
import { useEffect, useState } from "react";
import CustomTable from "../shared/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import pb from "../shared/pocketbase";
import alertDelete from "../shared/alert/alertDelete";
import { Check, Close } from "@mui/icons-material";
import { deleteDriverTag, getDriverTag } from "../features/driver/data/driver.api";
import DriverTagForm from "../features/driver/components/DriverTagForm";
import { InterfaceDriverTagData } from "../features/driver/data/driver.interface";

export default function DriverPageTag(props: { driverId?: string }) {

    const dispatch = useDispatch<any>()

    const { dataTagList, dataTagTotal } = useSelector((state: RootState) => state.driver)

    const [pagination, setPagination] = useState<{ page: number, perPage: number }>({ page: 1, perPage: 5 })
    const [selectedIdList, setSelectedIdList] = useState<string[]>([])
    const [addMode, setAddMode] = useState<boolean>(false)

    useEffect(() => {
        dispatch(getDriverTag({ ...pagination, driverId: props.driverId || '' }))
        pb.collection('driver_tags').subscribe('*', function (e) {
            dispatch(getDriverTag({ ...pagination, driverId: props.driverId || '' }))
        }, {});
        return () => {
            pb.collection('driver_tags').unsubscribe('*');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, pagination, props.driverId])

    const handleAdd = () => {
        if (selectedIdList.length > 0) {
            setSelectedIdList([])
            const i = setTimeout(() => {
                setAddMode(!addMode)
                clearTimeout(i)
            }, 100);
        }
        else {
            setAddMode(!addMode)
        }
    }

    const handleDelete = () => {
        alertDelete().then(async (result) => {
            if (result) {
                for (const i in selectedIdList) {
                    if (Object.prototype.hasOwnProperty.call(selectedIdList, i)) {
                        const element = selectedIdList[i];
                        await dispatch(deleteDriverTag(element))
                    }
                }
                setSelectedIdList([])
            }
        })
    }

    const openSidetab = selectedIdList.length === 1 || addMode

    return (
        <Split style={{ display: "flex", flexGrow: 1 }}>
            <Box flexGrow={1} padding={1} overflow={"auto"}>
                <Container>
                    <Typography variant="h3" gutterBottom>Screen</Typography>
                    <Box display={"flex"} gap={1} justifyContent={"flex-end"} marginBottom={2}>
                        <Button onClick={handleAdd}>{addMode ? "Close Add Panel" : "Add"}</Button>
                        {selectedIdList.length > 0 && (<Button color="error" onClick={handleDelete}>Delete Selected</Button>)}
                    </Box>
                    <CustomTable
                        dataList={dataTagList}
                        dataTotal={dataTagTotal}
                        pagination={(data) => setPagination(data)}
                        columns={[
                            {
                                name: "Name",
                                key: "name"
                            },
                            {
                                name: "Description",
                                key: "description"
                            },
                            {
                                name: "Address",
                                key: "address"
                            },
                            {
                                name: "Unit",
                                key: "unit"
                            },
                            {
                                name: "Data Type In",
                                key: "data_type"
                            },
                            {
                                name: "Data Type Out",
                                key: "output_data_type"
                            },
                            {
                                name: "Scaling Input Min",
                                key: "scaling_input_min"
                            },
                            {
                                name: "Scaling Input Max",
                                key: "scaling_input_max"
                            },
                            {
                                name: "Scaling Output Min",
                                key: "scaling_output_min"
                            },
                            {
                                name: "Scaling Output Max",
                                key: "scaling_output_max"
                            },
                            {
                                name: "Clamp Scaling Output",
                                key: "clamp_scaling_output",
                                render: (data: InterfaceDriverTagData) => (data.clamp_scaling_output ? <Check /> : <Close />)
                            }
                        ]}
                        selectedIdList={selectedIdList}
                        setSelectedIdLIst={setSelectedIdList}
                    />
                </Container>
            </Box>
            {openSidetab && (
                <Box flexGrow={1} padding={3} overflow={"auto"}>
                    {addMode
                        ? <Box>
                            <Typography variant="h3" gutterBottom>Add</Typography>
                            <DriverTagForm type="CREATE" driverId={props.driverId || ""} data={undefined} />
                        </Box>
                        : <Box>
                            <Typography variant="h3" gutterBottom>Detail</Typography>
                            <DriverTagForm type="EDIT" driverId={props.driverId || ""} data={dataTagList.find(data => data.id === selectedIdList[0])} />
                        </Box>
                    }
                </Box>
            )}
        </Split >
    )
}