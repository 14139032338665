import { createSlice } from '@reduxjs/toolkit'
import { InterfaceDriverCategoryData } from './driverCategory.interface'
import { createDriverCategory, deleteDriverCategory, getDriverCategory, updateDriverCategory } from './driverCategory.api'

export interface DriverCategoryState {
    loading: boolean,
    dataList: InterfaceDriverCategoryData[],
    dataTotal: number
}

const initialState: DriverCategoryState = {
    loading: false,
    dataList: [],
    dataTotal: 0
}

export const driverCategorySlice = createSlice({
    name: 'driverCategory',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getDriverCategory.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getDriverCategory.fulfilled, (state, action) => {
            state.loading = false
            state.dataList = action.payload.items
            state.dataTotal = action.payload.totalItems
        })
        builder.addCase(getDriverCategory.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createDriverCategory.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createDriverCategory.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createDriverCategory.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(updateDriverCategory.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateDriverCategory.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateDriverCategory.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteDriverCategory.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteDriverCategory.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteDriverCategory.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default driverCategorySlice.reducer