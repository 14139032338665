import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { InterfaceScreenPanelContentData, InterfaceScreenPanelData, ListPanelType, TypePanelType } from "../data/screen.interface";
import FormText from "../../../shared/form/FormText";
import { useFormValues } from "../../../hooks/useFormValues";
import ScreenEditorPanelFormView from "./ScreenEditorPanelFormView";
import ScreenEditorPanelFormRaw from "./ScreenEditorPanelFormRaw";

export default function ScreenEditorPanelForm(props: { value: InterfaceScreenPanelData, onChange: (value: InterfaceScreenPanelData) => void }) {

    const { values, setValues, handleChange, handleChangeValues } = useFormValues<InterfaceScreenPanelContentData>(props.value.content)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        props.onChange({
            ...props.value,
            content: values
        })
    }

    const renderPanelContent = (type: TypePanelType) => {
        switch (type) {
            case "VIEW": return <ScreenEditorPanelFormView values={values} onChange={value => setValues(value)} />
            case "RAW_VIEW": return <ScreenEditorPanelFormRaw values={values} onChange={value => setValues(value)} />
            case "CHART":
                return (
                    <Box>
                        chart
                    </Box>
                )
            default:
                return null
        }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label={"Title"} name="title" value={values.title} onChange={handleChange} />
            <Autocomplete
                options={ListPanelType}
                value={values.panel_type}
                onChange={(e, value) => handleChangeValues("panel_type", value)}
                renderInput={(params) => <TextField {...params} label={"Panel Type"} />}
            />
            {renderPanelContent(values.panel_type)}
            <Box display={"flex"} justifyContent={"flex-end"}>
                <Button type="submit">Save</Button>
            </Box>
        </Box>
    )
}