import { Box, Button, Container, Typography } from "@mui/material";
import Split from "@uiw/react-split";
import { useEffect, useState } from "react";
import CustomTable from "../../shared/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { deleteDriverCategory, getDriverCategory } from "../../features/driverCategory/data/driverCategory.api";
import pb from "../../shared/pocketbase";
import DriverCategoryForm from "../../features/driverCategory/components/DriverCategoryForm";
import alertDelete from "../../shared/alert/alertDelete";

export default function DriverCategoryPage() {

    const dispatch = useDispatch<any>()

    const { dataList, dataTotal } = useSelector((state: RootState) => state.driverCategory)

    const [pagination, setPagination] = useState<{ page: number, perPage: number }>({ page: 1, perPage: 5 })
    const [selectedIdList, setSelectedIdList] = useState<string[]>([])
    const [addMode, setAddMode] = useState<boolean>(false)

    useEffect(() => {
        dispatch(getDriverCategory(pagination))
        pb.collection('driver_categories').subscribe('*', function (e) {
            dispatch(getDriverCategory(pagination))
        }, {});
        return () => {
            pb.collection('driver_categories').unsubscribe('*');
        }
    }, [dispatch, pagination])

    const handleAdd = () => {
        if (selectedIdList.length > 0) {
            setSelectedIdList([])
            const i = setTimeout(() => {
                setAddMode(!addMode)
                clearTimeout(i)
            }, 100);
        }
        else {
            setAddMode(!addMode)
        }
    }

    const handleDelete = () => {
        alertDelete().then(async (result) => {
            if (result) {
                for (const i in selectedIdList) {
                    if (Object.prototype.hasOwnProperty.call(selectedIdList, i)) {
                        const element = selectedIdList[i];
                        await dispatch(deleteDriverCategory(element))
                    }
                }
                setSelectedIdList([])
            }
        })
    }

    const openSidetab = selectedIdList.length === 1 || addMode

    return (
        <Split style={{ display: "flex", flexGrow: 1 }}>
            <Box flexGrow={1} padding={1} overflow={"auto"}>
                <Container>
                    <Typography variant="h3" gutterBottom>Driver Category</Typography>
                    <Box display={"flex"} gap={1} justifyContent={"flex-end"} marginBottom={2}>
                        <Button onClick={handleAdd}>{addMode ? "Close Add Panel" : "Add"}</Button>
                        {selectedIdList.length > 0 && (<Button color="error" onClick={handleDelete}>Delete Selected</Button>)}
                    </Box>
                    <CustomTable
                        dataList={dataList}
                        dataTotal={dataTotal}
                        pagination={(data) => setPagination(data)}
                        columns={[
                            {
                                name: "Name",
                                key: "name"
                            }
                        ]}
                        selectedIdList={selectedIdList}
                        setSelectedIdLIst={setSelectedIdList}
                    />
                </Container>
            </Box>
            {openSidetab && (
                <Box flexGrow={1} padding={3} overflow={"auto"}>
                    {addMode
                        ? <Box>
                            <Typography variant="h3" gutterBottom>Add</Typography>
                            <DriverCategoryForm type="CREATE" data={undefined} />
                        </Box>
                        : <Box>
                            <Typography variant="h3" gutterBottom>Detail</Typography>
                            <DriverCategoryForm type="EDIT" data={dataList.find(data => data.id === selectedIdList[0])} />
                        </Box>
                    }
                </Box>
            )}
        </Split >
    )
}