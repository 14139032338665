import { TextField } from "@mui/material";
import { ChangeEventHandler } from "react";

export default function FormNumber(props: {
    label: string,
    name?: string,
    value: number,
    required?: boolean,
    disabled?: boolean,
    onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    readonly?: boolean
}) {
    return (
        <TextField
            fullWidth
            type="number"
            label={props.label}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            required={props.required}
            disabled={props.disabled}
            InputProps={{ readOnly: props.readonly }}
        />
    )
}