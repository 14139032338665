import { Box, Button, Container, Typography } from "@mui/material";
import Split from "@uiw/react-split";
import { useEffect, useState } from "react";
import CustomTable from "../shared/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { deleteDriver, getDriver } from "../features/driver/data/driver.api";
import pb from "../shared/pocketbase";
import DriverForm from "../features/driver/components/DriverForm";
import alertDelete from "../shared/alert/alertDelete";
import { InterfaceDriverData } from "../features/driver/data/driver.interface";
import DriverPageTag from "./DrierPageTag";

export default function DriverPage() {

    const dispatch = useDispatch<any>()

    const { dataList, dataTotal } = useSelector((state: RootState) => state.driver)

    const [pagination, setPagination] = useState<{ page: number, perPage: number }>({ page: 1, perPage: 5 })
    const [selectedIdList, setSelectedIdList] = useState<string[]>([])
    const [addMode, setAddMode] = useState<boolean>(false)

    useEffect(() => {
        dispatch(getDriver(pagination))
        pb.collection('drivers').subscribe('*', function (e) {
            dispatch(getDriver(pagination))
        }, {});
        return () => {
            pb.collection('drivers').unsubscribe('*');
        }
    }, [dispatch, pagination])

    const handleAdd = () => {
        if (selectedIdList.length > 0) {
            setSelectedIdList([])
            const i = setTimeout(() => {
                setAddMode(!addMode)
                clearTimeout(i)
            }, 100);
        }
        else {
            setAddMode(!addMode)
        }
    }

    const handleDelete = () => {
        alertDelete().then(async (result) => {
            if (result) {
                for (const i in selectedIdList) {
                    if (Object.prototype.hasOwnProperty.call(selectedIdList, i)) {
                        const element = selectedIdList[i];
                        await dispatch(deleteDriver(element))
                    }
                }
                setSelectedIdList([])
            }
        })
    }

    const openSidetab = selectedIdList.length === 1 || addMode
    const openBottomTab = selectedIdList.length === 1

    return (
        <Split mode="vertical" style={{ display: "flex", flexGrow: 1 }}>
            <Split style={{ display: "flex", flexGrow: 1 }}>
                <Box flexGrow={1} padding={1} overflow={"auto"}>
                    <Container>
                        <Typography variant="h3" gutterBottom>Driver</Typography>
                        <Box display={"flex"} gap={1} justifyContent={"flex-end"} marginBottom={2}>
                            <Button onClick={handleAdd}>{addMode ? "Close Add Panel" : "Add"}</Button>
                            {selectedIdList.length > 0 && (<Button color="error" onClick={handleDelete}>Delete Selected</Button>)}
                        </Box>
                        <CustomTable
                            dataList={dataList}
                            dataTotal={dataTotal}
                            pagination={(data) => setPagination(data)}
                            columns={[
                                {
                                    name: "Name",
                                    key: "name"
                                },
                                {
                                    name: "Description",
                                    key: "description"
                                },
                                {
                                    name: "Category",
                                    key: "category",
                                    render: (data: InterfaceDriverData) => data.expand.category?.name
                                },
                                {
                                    name: "Binary File",
                                    key: "binary_file",
                                    render: (data: InterfaceDriverData) => data.expand.binary_file?.name
                                }
                            ]}
                            selectedIdList={selectedIdList}
                            setSelectedIdLIst={setSelectedIdList}
                        />
                    </Container>
                </Box>
                {openSidetab && (
                    <Box flexGrow={1} padding={3} overflow={"auto"}>
                        {addMode
                            ? <Box>
                                <Typography variant="h3" gutterBottom>Add</Typography>
                                <DriverForm type="CREATE" data={undefined} />
                            </Box>
                            : <Box>
                                <Typography variant="h3" gutterBottom>Detail</Typography>
                                <DriverForm type="EDIT" data={dataList.find(data => data.id === selectedIdList[0])} />
                            </Box>
                        }
                    </Box>
                )}
            </Split >
            {openBottomTab && (
                <DriverPageTag driverId={selectedIdList[0]} />
            )}
        </Split >
    )
}