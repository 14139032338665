import { TextField } from "@mui/material";
import { ChangeEventHandler } from "react";

export default function FormJson(props: {
    label: string,
    name: string,
    value: Record<string, any> | undefined,
    required?: boolean,
    disabled?: boolean,
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}) {
    return (
        <TextField
            fullWidth
            multiline
            minRows={3}
            label={props.label}
            name={props.name}
            value={typeof props.value === "object" ? JSON.stringify(props.value, null, 4) : props.value}
            onChange={props.onChange}
            required={props.required}
            disabled={props.disabled}
        />
    )
}