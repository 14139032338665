import { createSlice } from '@reduxjs/toolkit'
import { InterfaceDriverBinaryData } from './driverBinary.interface'
import { createDriverBinary, deleteDriverBinary, getDriverBinary, updateDriverBinary } from './driverBinary.api'

export interface DriverBinaryState {
    loading: boolean,
    dataList: InterfaceDriverBinaryData[],
    dataTotal: number
}

const initialState: DriverBinaryState = {
    loading: false,
    dataList: [],
    dataTotal: 0
}

export const driverBinarySlice = createSlice({
    name: 'driverBinary',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getDriverBinary.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getDriverBinary.fulfilled, (state, action) => {
            state.loading = false
            state.dataList = action.payload.items
            state.dataTotal = action.payload.totalItems
        })
        builder.addCase(getDriverBinary.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createDriverBinary.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createDriverBinary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createDriverBinary.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(updateDriverBinary.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateDriverBinary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateDriverBinary.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteDriverBinary.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteDriverBinary.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteDriverBinary.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default driverBinarySlice.reducer