import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getDriverBinary } from "../data/driverBinary.api";

export default function DriverBinarySelect(props: { value: string | undefined, onChange: (value: string | undefined) => void }) {

    const dispatch = useDispatch<any>()

    const { loading, dataList } = useSelector((state: RootState) => state.driverBinary)

    useEffect(() => {
        dispatch(getDriverBinary({ page: 1, perPage: 999999 }))
    }, [dispatch])

    return (
        <Box>
            {loading
                ? "Loading..."
                : (
                    <Autocomplete
                        disablePortal
                        fullWidth
                        options={dataList}
                        getOptionLabel={options => options.name}
                        value={dataList.filter(data => data.id === props.value)[0]}
                        onChange={(_, value) => props.onChange(value?.id || undefined)}
                        renderInput={(params) => <TextField {...params} label="Driver Binary" required />}
                    />
                )
            }
        </Box>
    )
}