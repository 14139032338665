import { Box, Button } from "@mui/material";
import FormText from "../../../shared/form/FormText";
import { InitialDatasourceData, InterfaceDatasourceData } from "../data/datasource.interface";
import { useFormValues } from "../../../hooks/useFormValues";
import { useDispatch } from "react-redux";
import { createDatasource, updateDatasource } from "../data/datasource.api";
import FormArea from "../../../shared/form/FormArea";
import FormJson from "../../../shared/form/FormJson";
import DriverSelect from "../../driver/components/DriverSelect";
import FormNumber from "../../../shared/form/FormNumber";
import FormCheck from "../../../shared/form/FormCheck";
import { useEffect } from "react";

export default function DatasourceForm(props: { type: "CREATE" | "EDIT", data?: InterfaceDatasourceData }) {

    const dispatch = useDispatch<any>()

    const { values, setValues, handleChange, handleChangeValues } = useFormValues<InterfaceDatasourceData>(props.data || InitialDatasourceData)

    useEffect(() => {
        setValues(props.data || InitialDatasourceData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (props.type === "CREATE") {
            await dispatch(createDatasource(values))
        }
        else if (props.type === "EDIT") {
            await dispatch(updateDatasource(values))
        }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label={"Name"} name="name" value={values?.name} onChange={handleChange} />
            <FormArea label={"Description"} name="description" value={values?.description} onChange={handleChange} />
            <DriverSelect value={values?.driver} onChange={value => handleChangeValues("driver", value)} />
            <FormJson label={"Config"} name="config" value={values?.config} onChange={handleChange} />
            <FormNumber label={"Read Interval"} name="read_interval" value={values?.read_interval} onChange={handleChange} />
            <FormCheck label={"Enabled"} name="enabled" value={values?.enabled} onChange={handleChange} />
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={2}>
                <Button type="submit" disabled={values === props.data || values === InitialDatasourceData}>Submit</Button>
            </Box>
        </Box>
    )
}