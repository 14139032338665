import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: "#265073"
        },
        secondary: {
            main: "#edf4fa"
        },
        background: {
            default: "#f5f5f5"
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: "small",
                margin: "dense"
            }
        },
        MuiSelect: {
            defaultProps: {
                size: "small"
            }
        },
        MuiButton: {
            defaultProps: {
                variant: "contained"
            }
        },
        MuiContainer: {
            defaultProps: {
                sx: {
                    marginY: 2
                }
            }
        },
    }
});

export default theme;
