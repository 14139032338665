import { createSlice } from '@reduxjs/toolkit'
import { InterfaceDriverData, InterfaceDriverTagData } from './driver.interface'
import { createDriver, deleteDriver, getDriver, getDriverTag, updateDriver } from './driver.api'

export interface DriverState {
    loading: boolean,
    dataList: InterfaceDriverData[],
    dataTotal: number,
    dataTagList: InterfaceDriverTagData[],
    dataTagTotal: number
}

const initialState: DriverState = {
    loading: false,
    dataList: [],
    dataTotal: 0,
    dataTagList: [],
    dataTagTotal: 0
}

export const driverSlice = createSlice({
    name: 'driver',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getDriver.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getDriver.fulfilled, (state, action) => {
            state.loading = false
            state.dataList = action.payload.items
            state.dataTotal = action.payload.totalItems
        })
        builder.addCase(getDriver.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createDriver.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createDriver.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createDriver.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(updateDriver.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateDriver.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateDriver.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteDriver.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteDriver.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteDriver.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getDriverTag.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getDriverTag.fulfilled, (state, action) => {
            state.loading = false
            state.dataTagList = action.payload.items
            state.dataTagTotal = action.payload.totalItems
        })
        builder.addCase(getDriverTag.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default driverSlice.reducer