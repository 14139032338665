import { configureStore } from '@reduxjs/toolkit'

import screenReducer from './features/screen/data/screen.reducer';
import datasourceReducer from './features/datasource/data/datasource.reducer';
import viewReducer from './features/view/data/view.reducer';
import driverReducer from './features/driver/data/driver.reducer';
import driverBinaryReducer from './features/driverBinary/data/driverBinary.reducer';
import driverCategoryReducer from './features/driverCategory/data/driverCategory.reducer';

export const store = configureStore({
    reducer: {
        screen: screenReducer,
        datasource: datasourceReducer,
        view: viewReducer,
        driver: driverReducer,
        driverBinary: driverBinaryReducer,
        driverCategory: driverCategoryReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch