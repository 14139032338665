import { createSlice } from '@reduxjs/toolkit'
import { InterfaceViewData } from './view.interface'
import { createView, deleteView, getView, updateView } from './view.api'

export interface ViewState {
    loading: boolean,
    dataList: InterfaceViewData[],
    dataTotal: number
}

const initialState: ViewState = {
    loading: false,
    dataList: [],
    dataTotal: 0
}

export const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getView.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getView.fulfilled, (state, action) => {
            state.loading = false
            state.dataList = action.payload.items
            state.dataTotal = action.payload.totalItems
        })
        builder.addCase(getView.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createView.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createView.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createView.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(updateView.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateView.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateView.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteView.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteView.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteView.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default viewSlice.reducer