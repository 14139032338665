import { Box, CircularProgress, Modal } from "@mui/material"
import { useSelector } from "react-redux"
import { RootState } from "../../store"

export default function Loading() {

    const loadingDriverCategory = useSelector((state: RootState) => state.driverCategory.loading)

    const loading = loadingDriverCategory

    return (
        <Modal open={loading}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100vh"}>
                <CircularProgress />
            </Box>
        </Modal>
    )
}