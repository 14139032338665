export interface InterfaceDriverCategoryData {
    id: string,
    created: string,
    updated: string,
    name: string
}

export interface InterfaceDriverCategoryCreate {
    name: string
}

export interface InterfaceDriverCategoryUpdate {
    id: string,
    name?: string
}

export const InitialDriverCategoryData: InterfaceDriverCategoryData = {
    id: "",
    created: "",
    updated: "",
    name: ""
}