import { createSlice } from '@reduxjs/toolkit'
import { InitialScreenData, InterfaceScreenData } from './screen.interface'
import { createScreen, deleteScreen, getScreen, getScreenDetail, updateScreen } from './screen.api'

export interface ScreenState {
    loading: boolean,
    dataList: InterfaceScreenData[],
    dataTotal: number,
    dataDetail: InterfaceScreenData
}

const initialState: ScreenState = {
    loading: false,
    dataList: [],
    dataTotal: 0,
    dataDetail: InitialScreenData
}

export const screenSlice = createSlice({
    name: 'screen',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getScreen.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getScreen.fulfilled, (state, action) => {
            state.loading = false
            state.dataList = action.payload.items
            state.dataTotal = action.payload.totalItems
        })
        builder.addCase(getScreen.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(getScreenDetail.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getScreenDetail.fulfilled, (state, action) => {
            state.loading = false
            state.dataDetail = action.payload
        })
        builder.addCase(getScreenDetail.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createScreen.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createScreen.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createScreen.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(updateScreen.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateScreen.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateScreen.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteScreen.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteScreen.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteScreen.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default screenSlice.reducer