import { createAsyncThunk } from '@reduxjs/toolkit';
import pb from '../../../shared/pocketbase';
import { InterfaceDriverCreate, InterfaceDriverData, InterfaceDriverTagCreate, InterfaceDriverTagData, InterfaceDriverTagUpdate, InterfaceDriverUpdate } from './driver.interface';

export const getDriver = createAsyncThunk('getDriver', async (params: { page: number, perPage: number }) => await pb.collection('drivers').getList<InterfaceDriverData>(params.page, params.perPage, { expand: 'category, binary_file' }))
export const createDriver = createAsyncThunk('createDriver', async (data: InterfaceDriverCreate) => await pb.collection('drivers').create(data))
export const updateDriver = createAsyncThunk('updateDriver', async (data: InterfaceDriverUpdate) => await pb.collection('drivers').update(data.id, data))
export const deleteDriver = createAsyncThunk('deleteDriver', async (id: string) => await pb.collection('drivers').delete(id))

export const getDriverTag = createAsyncThunk('getDriverTag', async (params: { page: number, perPage: number, driverId: string }) => await pb.collection('driver_tags').getList<InterfaceDriverTagData>(params.page, params.perPage, { filter: `driver='${params.driverId}'` }))
export const createDriverTag = createAsyncThunk('createDriverTag', async (data: InterfaceDriverTagCreate) => await pb.collection('driver_tags').create(data))
export const updateDriverTag = createAsyncThunk('updateDriverTag', async (data: InterfaceDriverTagUpdate) => await pb.collection('driver_tags').update(data.id, data))
export const deleteDriverTag = createAsyncThunk('deleteDriverTag', async (id: string) => await pb.collection('driver_tags').delete(id))