import { Close } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, IconButton, Modal } from "@mui/material";
import { ReactNode } from "react";

export default function CustomModal(props: {
    open: boolean,
    title: string,
    onClose: () => void,
    component: ReactNode
}) {
    return (
        <Modal
            open={props.open}
        >
            <Box height={"100vh"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Card>
                    <CardHeader
                        title={
                            <span>
                                {props.title}
                            </span>
                        }
                        action={
                            <IconButton onClick={props.onClose}>
                                <Close />
                            </IconButton>
                        }
                    />
                    <CardContent sx={{ maxHeight: "80vh", maxWidth: "80vw", overflow: "auto" }}>
                        {props.open && props.component}
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    )
}