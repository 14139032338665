import { Box, Button } from "@mui/material";
import FormText from "../../../shared/form/FormText";
import { InitialScreenData, InterfaceScreenData } from "../data/screen.interface";
import { useFormValues } from "../../../hooks/useFormValues";
import { useDispatch } from "react-redux";
import { createScreen, updateScreen } from "../data/screen.api";
import FormArea from "../../../shared/form/FormArea";
import { useEffect } from "react";

export default function ScreenForm(props: { type: "CREATE" | "EDIT", data?: InterfaceScreenData }) {

    const dispatch = useDispatch<any>()

    const { values, setValues, handleChange } = useFormValues<InterfaceScreenData>(props.data || InitialScreenData)

    useEffect(() => {
        setValues(props.data || InitialScreenData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (props.type === "CREATE") {
            await dispatch(createScreen(values))
        }
        else if (props.type === "EDIT") {
            await dispatch(updateScreen(values))
        }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label={"Name"} name="name" value={values?.name} onChange={handleChange} />
            <FormArea label={"Description"} name="description" value={values?.description} onChange={handleChange} />
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={2}>
                <Button type="submit" disabled={values === props.data || values === InitialScreenData }>Submit</Button>
            </Box>
        </Box>
    )
}