import { Card } from "@mui/material";
import { InterfaceScreenPanelContentData } from "../data/screen.interface";
import { useFormValues } from "../../../hooks/useFormValues";
import { useEffect } from "react";
import CodeEditor from '@uiw/react-textarea-code-editor';

export default function ScreenEditorPanelFormRaw(props: { values: InterfaceScreenPanelContentData, onChange: (values: InterfaceScreenPanelContentData) => void }) {

    const { values, handleChangeValues } = useFormValues<InterfaceScreenPanelContentData>(props.values)

    useEffect(() => {
        props.onChange(values)
    }, [values])

    return (
        <Card variant="outlined" sx={{ width: "75vw", marginY: 1 }}>
            <CodeEditor
                value={values.svg || ""}
                language="js"
                placeholder="Please enter JS code."
                onChange={(evn) => handleChangeValues("svg", evn.target.value)}
                padding={15}
                style={{
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
            />
        </Card>
    )
}