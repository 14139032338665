import { Box, Button } from "@mui/material";
import FormText from "../../../shared/form/FormText";
import { InitialDriverCategoryData, InterfaceDriverCategoryData } from "../data/driverCategory.interface";
import { useFormValues } from "../../../hooks/useFormValues";
import { useDispatch } from "react-redux";
import { createDriverCategory, updateDriverCategory } from "../data/driverCategory.api";
import { useEffect } from "react";

export default function DriverCategoryForm(props: { type: "CREATE" | "EDIT", data?: InterfaceDriverCategoryData }) {

    const dispatch = useDispatch<any>()

    const { values, setValues, handleChange } = useFormValues<InterfaceDriverCategoryData>(props.data || InitialDriverCategoryData)

    useEffect(() => {
        setValues(props.data || InitialDriverCategoryData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (props.type === "CREATE") {
            await dispatch(createDriverCategory(values))
        }
        else if (props.type === "EDIT") {
            await dispatch(updateDriverCategory(values))
        }
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <FormText label={"Name"} name="name" value={values?.name} onChange={handleChange} />
            <Box display={"flex"} justifyContent={"flex-end"} marginTop={2}>
                <Button type="submit" disabled={values === props.data || values === InitialDriverCategoryData }>Submit</Button>
            </Box>
        </Box>
    )
}