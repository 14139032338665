import { Box } from "@mui/material";
import { InterfaceScreenPanelContentData } from "../data/screen.interface";
import ViewSelect from "../../view/components/ViewSelect";
import { useFormValues } from "../../../hooks/useFormValues";
import { useEffect } from "react";

export default function ScreenEditorPanelFormView(props: { values: InterfaceScreenPanelContentData, onChange: (values: InterfaceScreenPanelContentData) => void }) {

    const { values, handleChangeValues } = useFormValues<InterfaceScreenPanelContentData>(props.values)

    useEffect(() => {
        props.onChange(values)
    }, [values])

    return (
        <Box>
            <ViewSelect value={values.view_id} onChange={(value) => handleChangeValues("view_id", value)} />
        </Box>
    )
}