import { Box, Card, CardContent, Checkbox, FormControl, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

export default function CustomTable(props: {
    columns: Array<{
        name: string,
        key: string,
        align?: "left" | "center" | "right",
        render?: (data: any) => ReactNode
    }>,
    dataList: any[],
    dataTotal: number,
    pagination: (data: { page: number, perPage: number }) => void,
    selectedIdList?: string[],
    setSelectedIdLIst?: (idList: string[]) => void
}) {

    const [rowPerPage, setRowPerPage] = useState<number>(5)
    const [page, setPage] = useState<number>(1)
    const [idSelectedList, setIdSelectedList] = useState<string[]>([])

    useEffect(() => {
        setPage(1)
    }, [rowPerPage])

    useEffect(() => {
        props.pagination({ page, perPage: rowPerPage })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowPerPage])

    useEffect(() => {
        props.selectedIdList && setIdSelectedList(props.selectedIdList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedIdList])

    useEffect(() => {
        props.setSelectedIdLIst && props.setSelectedIdLIst(idSelectedList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSelectedList])

    return (
        <Card variant="outlined">
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {props.columns.map((column, i) => (
                                <TableCell key={i}>{column.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.dataList.map((data, rowIndex) => (
                            <TableRow key={rowIndex}>
                                <TableCell>
                                    <Checkbox checked={idSelectedList.includes(data.id)} onChange={(e) => { e.target.checked ? setIdSelectedList([...idSelectedList, data.id]) : setIdSelectedList(idSelectedList.filter(id => id !== data.id)) }} />
                                </TableCell>
                                {props.columns.map((column, colIndex) => (
                                    <TableCell key={colIndex} align={column.align}>
                                        {column.render
                                            ? column.render(data)
                                            : data[column.key]
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box marginTop={2} display={"flex"} gap={2} justifyContent={"right"} alignItems={"center"}>
                    <Box>
                        <FormControl fullWidth>
                            <Select
                                id="demo-simple-select"
                                value={rowPerPage}
                                onChange={e => setRowPerPage(Number(e.target.value))}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Pagination count={Math.ceil(props.dataTotal / rowPerPage)} color="primary" page={page} onChange={(e, value) => setPage(value)} />
                </Box>
            </CardContent>
        </Card>
    )
}
