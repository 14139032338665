import { createSlice } from '@reduxjs/toolkit'
import { InterfaceDatasourceData } from './datasource.interface'
import { createDatasource, deleteDatasource, getDatasource, updateDatasource } from './datasource.api'

export interface DatasourceState {
    loading: boolean,
    dataList: InterfaceDatasourceData[],
    dataTotal: number
}

const initialState: DatasourceState = {
    loading: false,
    dataList: [],
    dataTotal: 0
}

export const datasourceSlice = createSlice({
    name: 'datasource',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getDatasource.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getDatasource.fulfilled, (state, action) => {
            state.loading = false
            state.dataList = action.payload.items
            state.dataTotal = action.payload.totalItems
        })
        builder.addCase(getDatasource.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createDatasource.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createDatasource.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createDatasource.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(updateDatasource.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateDatasource.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateDatasource.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(deleteDatasource.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteDatasource.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteDatasource.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export default datasourceSlice.reducer