import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import _ from "lodash";
import axios from "axios";

export default function DashboardPage() {

    const values = {
        stacks: [{
            parameters: {
                NOX: {
                    value: Math.floor(Math.random() * 221)
                }
            }
        }],
        // pm10: {
        //     voltage: {
        //         value: Math.floor(Math.random() * 221)
        //     },
        //     current: {
        //         value: Math.floor(Math.random() * 221)
        //     }
        // }
    }

    const [view, setView] = useState("")

    useEffect(() => {
        getView()
    }, [])

    const insertValue = async () => {
        const texts = document.querySelectorAll('text[hawa]');
        for (const i in texts) {
            if (Object.prototype.hasOwnProperty.call(texts, i)) {
                const element = texts[i]
                const hawa = element.getAttribute('hawa')
                const hawaJson = JSON.parse(hawa || "{}")
                element.innerHTML = _.get(values, hawaJson.tag, "N/A")
            }
        }
    }

    const getView = () => {
        // axios.get("http://localhost:8090/api/files/dfjm90ejdrwicwh/6bk7811fm5lmmti/realtime_5_HK0Vsj8YpM.svg?token=")
        //     .then(res => {
        //         setView(res.data)
        //         insertValue()
        //     })
    }

    return (
        <Container component={"div"} maxWidth={"xl"}>
            <Box display={"flex"} dangerouslySetInnerHTML={{ __html: view }} />
        </Container>
    );
}