import { Box } from "@mui/material";
import { ReactNode } from "react";

export default function Content(props: { component: ReactNode }) {
    return (
        <Box display={"flex"} flexGrow={1} flexDirection={"column"} height={"100vh"}>
            <Box display={"flex"} flexGrow={1} overflow={"hidden"}>
                {props.component}
            </Box>
        </Box>
    )
}