import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import { FormEvent, useState } from "react";
import pb from "../../../shared/pocketbase";
import Swal from "sweetalert2";
import logo from "../../../assets/images/logo.png";

export default function Login() {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const handleLogin = async (e: FormEvent) => {
        e.preventDefault()
        try {
            await pb.collection("users").authWithPassword(username, password)
            window.location.href = "/"
        } catch (error: any) {
            Swal.fire("error", error.message, "error")
        }
    }

    return (
        <Box height={"100vh"} display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
            <Box display={"flex"} flexGrow={1} justifyContent={"center"}>
                <img src={logo} alt="logo" style={{ width: "50vw" }} />
            </Box>
            <Box display={"flex"} flexGrow={1} justifyContent={"center"}>
                <Card variant="outlined">
                    <CardContent>
                        <Box component={"form"} display={"flex"} flexDirection={"column"} onSubmit={handleLogin}>
                            <TextField label="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                            <TextField label="Password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
                            <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
                                <Button type="submit">Login</Button>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
}